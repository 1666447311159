<template>
  <ListRow>
    <td>
      <span :class="['badge_state', rowData.fixYn === 'Y' ? 'on' : '']">{{ fixYnText }}</span>
    </td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <!-- 상태값이 고정일 경우 link_point 클레스 추가 -->
      <router-link
        :to="toPath"
        :data-ellip="rowData.title"
        :class="[
          'link_subject',
          rowData.fixYn === 'Y' ? 'link_point' : '',
          rowData.fixYn == 'Y' && rowData.noticeType == 'M' ? 'link_bold' : '',
        ]"
        v-html="rowData.title"
      />
    </td>
    <td>{{ regDate }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import { makePathWithQuery } from "@/utils/urlUtils";
import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  name: "NoticeListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { seq } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.SYSTEM_NOTICE_VIEW, seq, this.$route.query);
      return path;
    },
    fixYnText() {
      switch (this.rowData.fixYn) {
        case "Y":
          return "고정";
        case "N":
          return "일반";
      }

      return "";
    },
  },
};
</script>
