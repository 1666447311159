<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">공지사항</h2>
      </div>
      <div class="body_section">
        <NoticeListFilterBar
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />
        <h3 class="screen_out">공지사항 목록</h3>
        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />

            <col style="width: 244px" />
          </template>
          <template v-slot:tr>
            <th>공지 상태</th>
            <th>제목</th>
            <th>공지 등록일</th>
          </template>
          <template v-slot:row="slotProps">
            <NoticeListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="3" class="td_empty">공지가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import NoticeListFilterBar from "@/components/user/system/notice/list/NoticeListFilterBar";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";
import NoticeListLine from "@/components/user/system/notice/list/NoticeListLine";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

import { dateToString, stringToDate } from "@/utils/dateUtils";

export default {
  components: {
    PageWithLayout,
    NoticeListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    NoticeListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.NOTICE,
      KEYWORD_DATAS: [
        { id: "title", text: "제목" },
        { id: "contents", text: "내용" },
      ],
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    // this.$store.dispatch( ANNOUNCE_LIST_ACTION ).then( () => {
    this.getDataInit(params);
    // } );
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      const objDates = this.checkQueryDates(query, "regDate1", "regDate2");

      // const objStatus = this.checkQueryStatus(query);

      // const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objDates);
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        // status,
        // category0, category1, category2,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["regDate1"] = startDt;
        params["regDate2"] = endDt;
      }

      // if (status) {
      //   params.status = status;
      // }

      // const cateNum = category2 || category1 || category0;
      // if( cateNum ) {
      //   params.cateNum = cateNum;
      // }

      return params;
    },
    onClickAdd() {
      this.goWrite();
    },
    async goWrite() {
      await this.$router.push({
        path: this.$routerPath.SYSTEM_NOTICE_WRITE,
      });
    },
  },
};
</script>
